import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import './Orders.css';
import ExpandOrderModal from './ExpandOrderModal';
import ModifyItemModal from './ModifyItemModal';
import ExitWSModal from './ExitWSModal';
import ConfirmationModal from './ConfirmationModal';
import BrokerSettingsModal from './BrokerSettingsModal';
import UserConfigModal from './UserConfigModal';

import config from '../config/config';

function Orders() {
  const location = useLocation();
  const { userId, algoUser } = location.state || {};
  let { userName } = location.state || {};
  const navigate = useNavigate();
  const width = window.innerWidth;

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = String(date.getUTCFullYear()).slice(-2);
  
    return `${day}/${month}/${year}`;
  };

  function formatToHHMM(dateString) {
    const date = new Date(dateString);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}


  const [fromDate, setFromDate] = useState(getTodayDate());
  const [toDate, setToDate] = useState(getTodayDate());
  const [scripName] = useState('ALL');
  const [statusFilter, setStatusFilter] = useState([]);
  const [orders, setOrders] = useState([]);
  const [scrips, setScrips] = useState([]);
  const [ordersError, setOrdersError] = useState(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderExpand, setSelectedOrderExpand] = useState(null);
  const [isProdOrdersOnly, setIsProdOrdersOnly] = useState(false);
  const [isNullReasonsOnly, setIsNullReasonsOnly] = useState(false);
  const [selectedUsernames, setSelectedUsernames] = useState([]);
  const [selectedStrategies, setSelectedStrategies] = useState([]);
  const [selectedScrips, setSelectedScrips] = useState([]);
  const [totalPnl, setTotalPnl] = useState(0);
  const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
  const [selectedOrderForStatusChange, setSelectedOrderForStatusChange] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedOrderBook, setSelectedOrderBook] = useState(null);
  // const [showOrderBookModal, setShowOrderBookModal] = useState(false);
  const [showBrokerSettingsModal, setShowBrokerSettingsModal] = useState(false);
  const [showUserConfigModal, setShowUserConfigModal] = useState(false);
  const [showModifySLModal, setShowModifySLModal] = useState(false);
  const [showModifyTPModal, setShowModifyTPModal] = useState(false);
  const [showExitWSModal, setShowExitWSModal] = useState(false);
  const [algoStatus, setAlgoStatus] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  // const [selectedOrders, setSelectedOrders] = useState([]); //For Order Checkbox
  const [redirectUrl, setRedirectUrl] = useState('');
  const [redirectUrlExists, setRedirectUrlExists] = useState(false);

  useEffect(() => {
    fetchIsAdmin(userName);
    fetchAlgoStatus();
  });

  useEffect(() => {
    fetchScrips();
  }, []);

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#f0f0f0',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#f0f0f0',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#f0f0f0',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#444',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#f0f0f0',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#666' : state.isFocused ? '#555' : '#333',
      color: '#f0f0f0',
    }),
  };
  
  const handleFromDateChange = (event) => setFromDate(event.target.value);
  const handleToDateChange = (event) => setToDate(event.target.value);
  const handleScripChange = (selectedOptions) => setSelectedScrips(selectedOptions.map(option => option.value));
  const handleStatusFilterChange = (selectedOptions) => setStatusFilter(selectedOptions.map(option => option.value));
  const handleUserChange = (selectedOptions) => setSelectedUsernames(selectedOptions.map(option => option.value));
  const handleStrategyChange = (selectedOptions) => setSelectedStrategies(selectedOptions.map(option => option.value));
  const handleProdOrdersOnlyChange = (e) => setIsProdOrdersOnly(e.target.checked);
  const handleNullReasonsOnlyChange = (e) => setIsNullReasonsOnly(e.target.checked);
  const handleLogout = () => navigate('/');
  const handleBSettings = () => setShowBrokerSettingsModal(true);
  const handleUserConfig = () => setShowUserConfigModal(true);
  const handleModifySL = () => setShowModifySLModal(true);
  const handleModifyTP = () => setShowModifyTPModal(true);
  const handleExitWS = () => setShowExitWSModal(true);
  const handleMonitor = () => {
    window.open('/monitor', '_blank',{state: {token: localStorage.getItem('token')}});
  };

  const handleStartStop = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirm = () => {
    updateAlgoStatus();
    setIsConfirmModalOpen(false);
    fetchAlgoStatus();
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
  };

  const uniqueScrips = useMemo(() => scrips.map(scrip => ({ value: scrip, label: scrip })), [scrips]);

  const uniqueStatuses = useMemo(() => Array.from(new Set(orders.map(order => order.status))).map(status => ({
    value: status,
    label: status,
  })), [orders]);

  const uniqueUsernames = useMemo(() => Array.from(new Set(orders.map(order => order.user_name))).map(username => ({
    value: username,
    label: username,
  })), [orders]);

  const uniqueStrategies = useMemo(() => Array.from(new Set(orders.map(order => order.strategy_name))).map(strategy => ({
    value: strategy,
    label: strategy,
  })), [orders]);

  const filteredOrders = useMemo(() => {
    if (!Array.isArray(orders)) return [];
    
    const filtered = orders.filter(order => {
      const isProdOrder = !isProdOrdersOnly || order.buy_order_id !== "0";
      const isNullReason = !isNullReasonsOnly || order.reason === "" || order.reason === null;
      const isStatusSelected = statusFilter.length === 0 || statusFilter.includes(order.status);
      const isUserSelected = selectedUsernames.length === 0 || selectedUsernames.includes(order.user_name);
      const isStrategySelected = selectedStrategies.length === 0 || selectedStrategies.includes(order.strategy_name);
      
      // Check if the order's scrip_name starts with "BANKNIFTY" or "NIFTY"
      const isScripSelected = selectedScrips.length === 0 || selectedScrips.some(scrip => 
        order.scrip_name.startsWith(scrip)
      );
      
      return isProdOrder && isNullReason && isUserSelected && isStrategySelected && isStatusSelected && isScripSelected;
    });
    
    return filtered;
  }, [orders, isProdOrdersOnly, isNullReasonsOnly, selectedUsernames, selectedStrategies, statusFilter, selectedScrips]);
  
  useEffect(() => {
    // Calculate total PNL based on filtered orders
    const total = filteredOrders.reduce((sum, order) => sum + (parseFloat(order.pnl) || 0), 0);
    setTotalPnl(total);
  }, [filteredOrders]);

  // const isUsernamePresent = useMemo(() => Array.isArray(orders) && orders.some(order => order.user_name && order.user_name.trim() !== ""), [orders]);

  const fetchIsAdmin = async (username) => {
    try {
      const response = await fetch(`${config.API_URL}/is-admin/${username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching job status');
      }
      const data = await response.json();
      setIsUserAdmin(data.status);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchOrders = useCallback(async (jsonFetchOrders) => {
    try {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      const response = await fetch(`${config.API_URL}/weborders/filter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(jsonFetchOrders),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'No orders found');
      }

      const data = await response.json();
      setOrders(data);
      console.log('Orders:', data);
      setOrdersError(null);
    } catch (error) {
      console.error('Error:', error);
      setOrdersError('No orders found');
      setOrders([]);
    }
  }, []);

  const fetchScrips = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
      const response = await fetch(`${config.API_URL}/get-scrips`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching scrips');
      }
      const data = await response.json();
      setScrips(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchAlgoStatus = async () => {
    try {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      const response = await fetch(`${config.API_URL}/algo/status/username/${userName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching job status');
      }
      const data = await response.json();
      setAlgoStatus(data.status);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateAlgoStatus = async () => {
    const newStatus = algoStatus === 'RUNNING' ? 1 : 0;
    const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
    const response = await fetch(`${config.API_URL}/algo/status/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        user_name: userName,
        status: newStatus
      })
    });
    if (!response.ok) {
      throw new Error('Error updating algo status');
    }
    // const data = await response.json();
    setIsConfirmModalOpen(false);
  };

  const exitOrder = async (orderId) => {
    const jsonExitOrder = {
      order_id: orderId
    };
    const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
    const response = await fetch(`${config.API_URL}/exit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(jsonExitOrder)
    });
    if (!response.ok) {
      return response.json().then(err => { throw err; });
    }
    return await response.json();
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const jsonFetchOrders = {
      user_name: userName,
      scrip_name: scripName,
      order_status: statusFilter,
      from_date: fromDate,
      to_date: toDate
    };
    fetchOrders(jsonFetchOrders);
  };

  const handleExitOrder = (orderId) => {
    if (window.confirm('Are you sure you want to exit this order?')) {
      exitOrder(orderId)
        .then(data => {
          setPopupMessage('Exit Successfully');
          setShowPopup(true);
          const jsonFetchOrders = {
            user_name: userName,
            scrip_name: scripName,
            order_status: statusFilter,
            from_date: fromDate,
            to_date: toDate
          };
          fetchOrders(jsonFetchOrders);
        })
        .catch(err => {
          setPopupMessage('Failed to exit order');
          setShowPopup(true);
        });
    }
  };

  const handleModifyOrder = () => {
    const { id, last_stop_loss, target_price } = selectedOrder;
    const jsonModifyOrders = {
      id,
      last_stop_loss,
      target_price
    };

    if (window.confirm('Are you sure you want to save the changes?')) {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      fetch(`${config.API_URL}/modify-stop-loss`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(jsonModifyOrders)
      })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => { throw err; });
        }
        return response.json();
      })
      .then(data => {
        setPopupMessage('Modified Successfully');
        setShowPopup(true);
        setShowModifyModal(false);
        if (!algoUser) {
          userName = 'ashok';
        }
        const jsonFetchOrders = {
          user_name: userName,
          scrip_name: scripName,
          order_status: statusFilter,
          from_date: fromDate,
          to_date: toDate
        };
        fetchOrders(jsonFetchOrders);
      })
      .catch(err => {
        setPopupMessage('Failed to modify order');
        setShowPopup(true);
      });
    }
  };

  const handleSaveStatusChange = async (orderId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found, please login');
    }

    try {
      const response = await fetch(`${config.API_URL}/change-order-status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ orderId, newStatus: 'COMPLETE' }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to update status');
    }

    alert('Status updated to COMPLETE successfully!');
    setShowStatusChangeModal(false);

    // Update the UI
    /*setFilteredOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId ? { ...order, status: 'COMPLETE' } : order
      )
    );*/
    } catch (error) {
      alert(error.message || 'An error occurred while updating the status');
    }
  };

  const handleOrderbook = async (orderId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found, please login');
    }

    try {
      const response = await fetch(`${config.API_URL}/orderbook-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({order_id: orderId})
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to get order book');
    }

    const data = await response.json();
    setOrderDetails(data);
    setIsModalOpen(true);

    } catch (error) {
      alert(error.message || 'An error occurred while getting the order book');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setOrderDetails(null);
  };

  const handleExpandOrder = (order) => setSelectedOrderExpand(order);
  // const handleCloseOrderBookModal = () => setShowOrderBookModal(false);
  const handleClose = () => setSelectedOrderExpand(null);

  const shouldShowActions = (status) => {
    const statusesToExclude = ['COMPLETE', 'CANCELED', 'CANCELLED', 'STOPLOSS_COMPLETE', 'SQUARRED_OFF'];
    return !statusesToExclude.includes(status);
  };

  const handleModifyButtonClick = (order) => {
    if (order.status === 'ERROR') {
      setSelectedOrderForStatusChange(order);
      setShowStatusChangeModal(true);
    } else {
      setSelectedOrder(order);
      setShowModifyModal(true);
    }
  };

  const handleBookSLOrder = async (orderId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
      const response = await fetch(`${config.API_URL}/book-sl-order`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ order_id: orderId })
      });
      if (!response.ok) {
        throw new Error('Error booking SL order');
      }
      const data = await response.json();
      setPopupMessage(`${data}`);
      setShowPopup(true);
    } catch (error) {
      setPopupMessage(`${error}`);
      setShowPopup(true);
    }
  };

  const fetchRedirectUrl = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
      const response = await fetch(`${config.API_URL}/getRedirectUrl/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching redirect url');
      }
      const data = await response.json();
      if(data.redirect_url) {
        setRedirectUrl(data.redirect_url);
        setRedirectUrlExists(true);
      }
      else {
        setRedirectUrlExists(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchRedirectUrl();
  },);

  const actionText = algoStatus === 'RUNNING' ? 'Stop' : 'Start';

  return (
    <div className="page-container">
      <div className="username-container">
        {userName && <p> Welcome, {userName}!</p>}
        <button className='logout-button' onClick={handleLogout}>Logout</button>
      </div>
      <div className="actions-container">
        <button className='back-button-orders' onClick={() => navigate(-1)}>Back</button>
        {redirectUrlExists && <button className='login-button-orders' onClick={() => window.open(redirectUrl, '_blank')}>Login</button>}
        {algoUser && (<>
          <button className="settings-button" onClick={handleBSettings}>BrokerSettings</button>
          <button className="settings-button" onClick={handleUserConfig}>Config</button>
          <button className={`start-stop-button ${algoStatus === 'RUNNING' ? 'stop' : 'start'}`}
            onClick={handleStartStop}
          >
            {algoStatus === 'RUNNING' ? 'Stop Algo' : 'Start Algo'}
          </button>
        </>)}
        {isUserAdmin === 1 && (
          <>
            <button className='expiry-dates-button' onClick={() => navigate('/expiry-dates', { state: { userId, userName } })}>Exp Dates</button>
            <button className='register-user-button' onClick={() => navigate('/registration', { state: { isAdmin: true } })}>Register</button>
            <button className='monitor-button' onClick={handleMonitor}>Monitor</button>
            <button className="modify-sl-wholesale-button" onClick={handleModifySL}>Mf.SL</button>
            <button className='modifyitarget-wholesale-button' onClick={handleModifyTP}>Mf.TP</button>
            <button className="exit-ws-button" onClick={handleExitWS}>ExitWS</button>
          </>
        )}
        {/* <button className="run-strategy-button" onClick={handleRunStrategy}>Run Strategy</button> */}
      </div>
      <h2 style={{marginTop: '30px'}}>Orders</h2>
      <div className="controls">
        <div className="date-selection">
          <label>
            From Date:
            <input type="date" value={fromDate} onChange={handleFromDateChange} />
          </label>
        </div>
        <div className="date-selection">
        <label>
            To Date:
            <input type="date" value={toDate} onChange={handleToDateChange} />
          </label>
        </div>
        <div className="dropdown-selection">
          <label>
            Select Scrip
            <Select
              isMulti
              options={uniqueScrips}
              onChange={handleScripChange}
              styles={customSelectStyles}
            />
          </label>
        </div>
        <div className="dropdown-selection-filter">
          <label>
            Status Filter
            <Select
              isMulti
              options={uniqueStatuses}
              onChange={handleStatusFilterChange}
              styles={customSelectStyles}
            />
          </label>
        </div>
        {isUserAdmin === 1 && (
          <div className="dropdown-selection-filter">
            <label>
              Strategy
              <Select
                isMulti
                options={uniqueStrategies}
                onChange={handleStrategyChange}
                styles={customSelectStyles}
              />
            </label>
          </div>
        )}

        {isUserAdmin === 1 && (
          <div className="dropdown-selection-filter">
            <label>
              Select Users
              <Select
                isMulti
                options={uniqueUsernames}
                onChange={handleUserChange}
                styles={customSelectStyles}
              />
            </label>
          </div>
        )}
        <div className="checkbox-selection">
          <label>Prod Orders Only</label>
          <input type="checkbox" checked={isProdOrdersOnly} onChange={handleProdOrdersOnlyChange} />
        </div>
        <div className="checkbox-selection">
          <label>Null Rerasons</label>
          <input type="checkbox" checked={isNullReasonsOnly} onChange={handleNullReasonsOnlyChange} />
        </div>
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>

      <div className="total-pnl-container">
        <h4>Total PnL: {totalPnl}</h4>
      </div>

      {ordersError ? (
  <p>{ordersError}</p>
) : (
  <div className="orders-table">
    {filteredOrders.length === 0 ? (
      <p>No orders found</p>
    ) : (
      <table className="table table-striped">
        <thead>
          <tr>
            {width > 578 && isUserAdmin === 1 && <th>Id_Num</th>}
            <th>Scrip Name</th>
            <th>P/L</th>
            {width > 578 && <th>Sell P.</th>}
            {width > 578 && <th>Last SL</th>}
            {width > 578 && <th>Last BP</th>}
            {width > 578 && <th>H.Price</th>}
            {width > 578 && <th>H.Time</th>}
            {width > 578 && <th>L.Price</th>}
            {width > 578 && <th>L.Time</th>}
            {width > 578 && <th>Buy P</th>}
            {width > 578 && <th>SL</th>}
            {width > 578 && <th>SL.Upd.</th>}
            {width > 578 && <th>TR</th>}
            {width > 578 && <th>TRT</th>}
            {width > 578 && <th>Date</th>}
            {width > 578 && <th>Sell Time</th>}
            {width > 578 && <th>Stgy</th>}
            {width > 578 && <th>Status</th>}
            {isUserAdmin === 1 && <th>Username</th>}
            {isUserAdmin === 1 && <th>Reason</th>}
            {isUserAdmin === 1 && filteredOrders.some(order => shouldShowActions(order.status)) && (
              <th>Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(filteredOrders) &&
            filteredOrders.map(order => (
              <tr key={order.id}>
                {width > 578 && isUserAdmin === 1 && (
                  <td>{order.id_num !== null ? order.id_num : 'N/A'}</td>
                )}
                <td>
                    <a
                      href={`/order-details/${order.id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleExpandOrder(order);
                      }}
                      style={{ textDecoration: 'underline', color: '#2880eb', cursor: 'pointer' }}
                    >
                      {order.scrip_name}
                      {order.buy_order_id === "0" ? " (TEST)" : ""}
                    </a>
                  </td>
                <td
                  style={{
                    color:
                      order.pnl === null
                        ? 'white'
                        : order.pnl < 0
                        ? 'red'
                        : order.pnl > 0
                        ? 'green'
                        : 'white',
                  }}
                >
                  {order.pnl !== null ? order.pnl : 'N/A'}
                </td>
                {width > 578 && <td>{order.sell_price !== null ? order.sell_price : 'N/A'}</td>}
                {width > 578 && <td>{order.last_stop_loss !== null ? order.last_stop_loss : 'N/A'}</td>}
                {width > 578 && <td>{order.last_buy_price !== null ? order.last_buy_price : 'N/A'}</td>}
                {width > 578 && <td>{order.high_price !== null ? order.high_price : 'N/A'}</td>}
                {width > 578 && <td>{formatToHHMM(order.high_datetime)}</td>}
                {width > 578 && <td>{order.low_price !== null ? order.low_price : 'N/A'}</td>}
                {width > 578 && <td>{formatToHHMM(order.low_datetime)}</td>}
                {width > 578 && <td>{order.buy_price}</td>}
                {width > 578 && <td>{order.stop_loss !== null ? order.stop_loss : 'N/A'}</td>}
                {width > 578 && <td>{formatToHHMM(order.sl_updated_datetime)}</td>}
                {width > 578 && <td>{order.target_revised}</td>}
                {width > 578 && <td>{order.target_revised_trail}</td>}
                {width > 578 && <td>{formatDateTime(order.order_buy_datetime)}</td>}
                {width > 578 && <td>{formatToHHMM(order.order_sell_datetime)}</td>}
                {width > 578 && <td>{order.strategy_name !== null ? order.strategy_name.substring(0, 3) : 'N/A'}</td>}
                {width > 578 && <td>{order.status.substring(0, 4)}</td>}
                {isUserAdmin === 1 && <td>{order.user_name}</td>}
                {isUserAdmin === 1 && <td>{order.reason ? order.reason.substring(0, 15) : "NA"}</td>}
                <td className="action-buttons">
                  <>
                    {isUserAdmin === 1 && shouldShowActions(order.status) && (
                      <>
                        <button className="modify-button" onClick={() => handleModifyButtonClick(order)}>Mod</button>
                        {order.status === 'PLACED' && (
                          <button className='book-sl-button' onClick={() => handleBookSLOrder(order.id)}>S</button>
                        )}
                        <button className="exit-button" onClick={() => handleExitOrder(order.id)}>Ex</button>
                      </>
                    )}
                    <button className='orderbook-button' onClick={() => handleOrderbook(order.id, order.user_name)}>OB</button>
                  </>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    )}
  </div>
)}

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>{popupMessage}</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}


      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        message={`Are you sure you want to ${actionText.toUpperCase()} the Algo for today? It will reset to the default state next day.`}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      {showBrokerSettingsModal && (
        <BrokerSettingsModal
          isAdmin={isUserAdmin === 1}
          userId={userId}
          userName={userName}
          onClose={() => setShowBrokerSettingsModal(false)}
          onSave={(settings) => {
            // Handle settings save logic here
            console.log('Input for Settings:', settings);
            setShowBrokerSettingsModal(false);
          }}
        />
      )}

      {showUserConfigModal && (
        <UserConfigModal
          isAdmin={isUserAdmin === 1}
          userId={userId}
          userName={userName}
          onClose={() => setShowUserConfigModal(false)}
          onSave={(settings) => {
            // Handle settings save logic here
            console.log('Input for Settings:', settings);
            setShowUserConfigModal(false);
          }}
        />
      )}

      {showModifySLModal && (
        <ModifyItemModal 
          isOpen={showModifySLModal} 
          onClose={() => setShowModifySLModal(false)}
          orders={orders} 
          fieldName="last_stop_loss" 
          fieldLabel="Last Stop Loss" 
          apiEndpoint="modify-stop-loss-all" 
        />
      )}

      {showModifyTPModal && (
        <ModifyItemModal 
          isOpen={showModifyTPModal} 
          onClose={() => setShowModifyTPModal(false)}
          orders={orders} 
          fieldName="target_price" 
          fieldLabel="Target Price" 
          apiEndpoint="modify-target-all" 
        />
      )}

      {showExitWSModal && (
        <ExitWSModal 
        isOpen={showExitWSModal} 
        onClose={() => setShowExitWSModal(false)} 
        orders={orders} 
      />
      )}

      {selectedOrderExpand && (
        <ExpandOrderModal order={selectedOrderExpand} onClose={handleClose} />
      )}

      {showModifyModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Modify Order</h2>
            <label>
              Stop Loss:
              <input
                type="text"
                value={selectedOrder.last_stop_loss}
                onChange={(e) => setSelectedOrder({ ...selectedOrder, last_stop_loss: e.target.value })}
              />
            </label>
            <label>
              Target Price:
              <input
                type="text"
                value={selectedOrder.target_price}
                onChange={(e) => setSelectedOrder({ ...selectedOrder, target_price: e.target.value })}
              />
            </label>
            <div className="modal-buttons">
              <button className="save-button" onClick={handleModifyOrder}>Save</button>
              <button className="cancel-button" onClick={() => setShowModifyModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {showStatusChangeModal && (
        <div className="modal-s">
          <div className="modal-content-s">
            <h2>Change Order Status</h2>
            <p>
              <strong>Order ID:</strong> {selectedOrderForStatusChange.id}
            </p>
            <p>
              Current Status: <strong>{selectedOrderForStatusChange.status}</strong>
            </p>
            <p>Do you want to change the status to <strong>COMPLETE</strong>?</p>
            <div className="modal-buttons-s">
              <button
                className="save-button-s"
                onClick={() => handleSaveStatusChange(selectedOrderForStatusChange.id)}
              >
                Save
              </button>
              <button
                className="cancel-button-s"
                onClick={() => setShowStatusChangeModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="modal-overlay-m">
          <div className="modal-content-m">
            <h3>Order Details</h3>
            <table>
              <thead>
                <tr>
                  <th>Scrip Name</th>
                  <th>Qty</th>
                  <th>Buy</th>
                  <th>Sell</th>
                  <th>PNL</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails && orderDetails.length > 0 ? (
                  orderDetails.map((detail, index) => (
                    <tr key={index} style={{ backgroundColor: detail.highlight ? 'red' : 'transparent' }}>
                      <td>{detail["Scrip Name"]}</td>
                      <td>{detail.Qty}</td>
                      <td>{detail.Buy !== null ? detail.Buy : "NA"}</td>
                      <td>{detail.Sell !== null ? detail.Sell : "NA"}</td>
                      <td>{detail.PNL !== null ? detail.PNL.toFixed(2) : "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>NA</td>
                    <td>NA</td>
                    <td>NA</td>
                    <td>NA</td>
                    <td>NA</td>
                  </tr>
                )}
              </tbody>
            </table>
            <button onClick={closeModal} className="close-button-m">
              Close
            </button>
          </div>
        </div>
      )}

    </div>
  );
}

export default Orders;